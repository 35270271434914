/**
 * formats a timestamp into "ShortWeekday DD Month YYYY HH:MM:SS"
 * @param {string} timestamp - a UNIX timestamp, with or without milliseconds
 * @returns {string} - formatted date-time
 */

export function getFormattedDateTime(timestamp: string, UTC: boolean): string {
  if (timestamp.length <= 10) {
    // add milliseconds if not there
    timestamp = timestamp + "000";
  };
  const st = new Date(Number(timestamp || 0));
  const timezone = (UTC ? "UTC" : Intl.DateTimeFormat().resolvedOptions().timeZone);
  const locale = 'en-GB';
  const startTime =
    new Intl.DateTimeFormat(locale, {
      weekday: 'short',
      timeZone: timezone
    }
    ).format(st) + " " +
    new Intl.DateTimeFormat(locale, {
      day: '2-digit',
      timeZone: timezone
    }
    ).format(st) + "/" +
    new Intl.DateTimeFormat(locale, {
      month: 'short',
      timeZone: timezone
    }
    ).format(st) + "/" +
    new Intl.DateTimeFormat(locale, {
      year: 'numeric',
      timeZone: timezone
    }
    ).format(st) + " " +
    new Intl.DateTimeFormat(locale, {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZone: timezone
    }).format(st);
  return startTime;
}

/**
 * Maps strings coming from Infoboard into
 * the 3 "traffic light" color names used by CircleIcon component
 */

export function ledColor(status: string): "success" | "warning" | "error" {
  status = status.toLowerCase();
  if (status === "ok" || status === "0") {
    return "success"
  } else if (status === "degraded" || status === "1") {
    return "warning"
  } else {
    return "error"
  }
};

export function getFormattedDuration(duration: string): string {
  const durationNumber = Number(duration || 0);
  const hours = Math.floor(durationNumber / 60)
  const minutes = durationNumber % 60;
  const formattedDuration = (hours > 0 ? hours + "h" : '') + (minutes > 0 ? " " + minutes + "'" : '')
  return formattedDuration;
}

/**
 * Parses a string and returns a UNIX timestamp
 * 
 * Extra massaging of date-time received from Infoboard needed  
 * because Date.parse() does not work in the same way across browsers,
 * 
 * date comes in this format: "Thu 01/Jan/1970 00:00:00 UTC" 
 * must be transformed into:  "01 Jan 1970 00:00:00 GMT"  
 * 
 * (the only officially supported format is "YYYY-MM-DDTHH:mm:ss.sssZ"
 * @see https://tc39.es/ecma262/multipage/numbers-and-dates.html#sec-date-time-string-format
 * but the above seems to work cross-browser too 
 * and does not requires explicit parsing of month's name)
 */

export function parseDateTime(date: string): number {
  return Date.parse(date.slice(4, -3).replaceAll("/", " ") + " GMT")
}

/**
 * Helper function to read cookies
 * @param name cookie name
 * @returns the value of the cookie, or undefined if cookie not set.
 */
export function getCookie(name: string): string | undefined {
  return document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${name}=`))
    ?.split("=")[1];
}

/**
 * Helper function to decode HTML entities.
 * Needed as apparently infoboard still spits out HTML strings in this way and not UTF-8
 * 
 */

export function decodeHtml(htmlString: string) {
  var txt = document.createElement("textarea");
  txt.innerHTML = htmlString;
  return txt.value;
}